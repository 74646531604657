@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    margin:0;
    padding:0;
    /* font-family:'Lato', sans-serif; */
    font-family: 'Poppins', sans-serif;
}

.hide-scrollbar::-webkit-scrollbar{
    display:none;
}